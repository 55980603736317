import React, { useEffect, useState } from "react";

import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";

import classes from "./Header.module.scss";
import { Link, useHistory } from "react-router-dom";

import logo from '../images/Ceilidhs_ENG_logo-white.svg';


const Header = () => {
    const history = useHistory();
    const [menuOpen, setMenuOpen] = useState(false);
    const [size, setSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const handleResize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (size.width > 768 && menuOpen) {
            setMenuOpen(false);
        }
    }, [size.width, menuOpen]);

    const menuToggleHandler = () => {
        setMenuOpen((p) => !p);
    };

    const ctaClickHandler = () => {
        menuToggleHandler();
        history.push("/page-cta");
    };

    return (
        <header className={classes.header}>
            <div className={classes.header__content}>
                {/* <Link to="/" className={classes.header__content__logo}> */}
                <Link to={{ pathname: "https://www.ceilidhswhisky.com/" }} target="_top" className={classes.header__content__logo} >
                    <img src={logo} 
                        alt="My logo"
                        height="28 px"   // 28vh - logo relative size to height of NAV bar
                        /> 
                </Link>
                <nav
                    className={`${classes.header__content__nav} ${
                        menuOpen && size.width < 768 ? classes.isMenu : ""
                    }`}
                >
                    <ul>
                        <li>
                            <Link to="/" onClick={menuToggleHandler}>
                                {/* Featured Collection */}
                                The Rebirth
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/page-one" onClick={menuToggleHandler}>
                                Test Collection 1
                            </Link>
                        </li> */}
                        {/* <li>
                            <Link to="/page-two" onClick={menuToggleHandler}>
                                Test Collection 2
                            </Link>
                        </li> */}
                        {/* <li>
                            <Link to="/page-three" onClick={menuToggleHandler}>
                                Collection 3
                            </Link>
                        </li> */}
                    </ul>
                    {/* <button onClick={ctaClickHandler}>CTA Page</button> */}
                </nav>
                <div className={classes.header__content__toggle}>
                    {!menuOpen ? (
                        <BiMenuAltRight onClick={menuToggleHandler} />
                    ) : (
                        <AiOutlineClose onClick={menuToggleHandler} />
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
