import React from "react";
import Iframe from "react-iframe";

const PageTwo = () => {
    return <Iframe
    src="https://opensea.io/assets/av-yacht-club?embed=true&ref=0x66E8f67e3bF3d687a2F282D22CDa4C0C11f868CF"
    width='100%'
    height='100%'
    // frameborder='0'
    // allowfullscreen
    />;
};

export default PageTwo;
